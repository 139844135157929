.sidebar {
  background-color: #37474f;
  position: absolute;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 3.5rem;
  z-index: 100;
  margin-top: 4rem;

  .menu {
    width: 3rem;
    height: calc(100vh - 5rem);

    .menu-link {
      display: contents;
      text-decoration: none;
    }
  }

  span svg {
    font-size: 1.3rem;
  }

  .sidebar-sortable {
    color: rgba(0, 0, 0, 0.4);
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: absolute;
    z-index: 10;
    background-color: #324850;

    .icon-arrow {
      margin-left: auto;
    }

    .sidebar-toggle-icon {
      border-bottom: 1px solid #c3c3c3;

      svg {
        margin-left: 0.25rem;
      }
    }

    .icon-wrapper {
      width: 2rem;
      justify-content: center;
    }

    .icon-label-wrapper {
      // margin-left: 1rem;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    .sublink:hover {
      background-color: #1717178a;
    }

    li {
      display: flex;
    }

    li span {
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      min-height: 2.875rem;
      padding: 0 0.75rem 0 0.75rem;
      margin-left: 0.375rem;
      color: #fff;
      text-decoration: none;
    }

    .sidebar-sortable-sub {
      background-color: #171717;
      position: absolute;
      left: 15rem;
      top: 0;
      width: 15rem;
      color: #fff;
      padding: 0 0rem;
      height: calc(100vh - 5rem);

      .sub-title {
        text-transform: uppercase;
        color: #afafaf;
        font-weight: bold;
        margin-left: 0;
      }

      h3 {
        margin-top: 0.75rem;
        padding: 0;
        border-bottom: 1px solid #ffffff5e;
        margin-bottom: 0.5rem;
        padding-bottom: 0.65rem;
        padding-left: 1rem;
        font-size: 1.25rem;
      }

      ul {
        list-style: disc;
      }

      li {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }

      ul li {
        display: list-item;
        list-style-type: none;
        border-bottom: 1px solid #ffffff5e;
        padding-left: 1rem;
      }

      ul li a {
        color: white;
        text-decoration: none;
      }

      ul li a:hover {
        color: #80bdff;
        text-decoration: none;
      }
    }
  }
}

.is-open {
  .sidebar-sortable {
    .menu {
      height: calc(100vh - 5rem);
      overflow: hidden;
      width: 15rem;
    }
  }
}
