.App {
  text-align: center;
}

body {
  overflow-y: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-container {
  height: calc(100vh - 4rem);
  width: calc(100% - 3.5rem);
  margin-top: 4rem !important;
  margin-left: 3.5rem !important;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
}

.rolagem {
  /* max-height: calc(100% - 4rem); */
  overflow-y: auto;
}

.print {
  page-break-after: always;
}

.page-full {
  max-height: 100%;
  height: 100%;
}

.page-100 {
  max-height: 100vh;
  height: 100vh;
  overflow-y: auto;
}

.qrcode {
  width: 150px;
  height: 150px;
  border: 1px solid #000;
  margin-top: 4px;
  margin-bottom: 4px;
}

.perfis-rolagem {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 1s0px;
}

.linhaTabela {
  vertical-align: middle;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
